//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("partner/getIncomes", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      return [{
        title: "Дата",
        show: true,
        name: "createdAt",
        width: 100,
        type: "date"
      }, {
        title: "Сумма",
        show: true,
        name: "amount",
        width: 100,
        filter: "numberRange",
        type: "money"
      }, {
        title: "Уровень комиссии",
        show: true,
        name: "percent",
        width: 100,
        filter: "numberRange",
        type: "percent"
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};