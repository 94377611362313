//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: null,
      default: null
    },
    variant: {
      type: String,
      default: "default",
      validate: v => ["default", "light"].includes(v)
    }
  },
  computed: {
    uid() {
      return this._uid;
    },
    hasDescriptions() {
      return this.items.some(i => !!i.description);
    }
  },
  methods: {
    onClick(item) {
      this.$emit("input", item.value);
    }
  }
};