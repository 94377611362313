//
//
//
//
//
//

import PartnerPage from "@/components/Partner/Page";
export default {
  components: {
    PartnerPage
  },
  meta: {
    title: "Тариф и оплата"
  }
};