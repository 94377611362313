//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      method: null,
      methods: [{
        text: "На расчётный счёт",
        value: "bank_invoice",
        description: "Для юр. лиц, ИП и НПД - комиссия 0%"
      }, {
        text: "На банковскую карту",
        value: "card",
        description: "Для физических лиц - комиссия 16%"
      }
      /*
      {
          text: "Криптовалюта (USDC)",
          value: "crypto",
          description: "Для физических лиц - комиссия 10%",
      },
      */]
    };
  },

  computed: {},
  methods: {
    async submit() {
      if (!this.method) {
        return;
      }
      this.$emit("complete", await this.$modal.process(() => import("@/components/Partner/ModalWithdrawal"), {
        method: this.method
      }));
    }
  }
};