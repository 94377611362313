//
//
//
//
//
//

export default {
  props: {
    variant: {
      type: String,
      default: "default",
      validate: v => ["default", "light"].includes(v)
    }
  }
};